import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Timeline from "../elements/Timeline";
import TimelineItem from "../elements/TimelineItem";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class Roadmap extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "roadmap section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "roadmap-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Experience",
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="April 2019 - Current">
                Software Developer
                <p className="companyName">KeeperAI</p>
                <ul className="experienceList">
                  <li>
                    <strong>
                      <u>Technical Lead:</u>
                    </strong>{" "}
                    Developed 4 different web applications from the ground up
                    using React.js integrated with Firebase for different
                    industry verticals
                    <ul>
                      <li>
                        Developed APIs for Slack integrations using Node.js
                        through a chatbot to further ease migration and use of
                        the platform
                      </li>
                      <li>
                        Supported data science team on creating, implementing,
                        and analyzing information and data structures, including
                        the development of web scrapers and natural language
                        processing sentiment analysis algorithms
                      </li>
                      <li>
                        Managed an agile team of 3 frontend and 2 backend
                        developers
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>
                      <u>Strategy responsibilities:</u>
                    </strong>{" "}
                    Increased sales and number of users in the platform by over
                    200% in 2020. Pitched to VCs and potential clients. Worked
                    on differentiation and ROI for the B2C, B2B, and licensing
                    markets. Created pitch decks, SOWs, business development
                    documents, pro forma financial models, and more
                  </li>
                  <li>
                    <strong>
                      <u>Agile team responsibilities: </u>
                    </strong>{" "}
                    Integrated and managed third-party APIs, hosted daily
                    standups, interviewed new technical team members,
                    participated in weekly innovation sessions, maintained
                    quality assurance, developed tests, and provided sales demo
                    support
                  </li>
                  <li>
                    <strong>
                      <u>IT responsibilities:</u>
                    </strong>{" "}
                    Configured and maintained company infrastructure.
                    Responsible for management of G Suite, all
                    development-related platforms and APIs, company payments,
                    corporate website, all product platforms, as well as
                    upkeeping all company legal documents and confidential
                    information
                  </li>
                  <li>Creator of two new patents (provisional utility)</li>
                </ul>
              </TimelineItem>
              <TimelineItem title="July 2020 - Current">
                Freelance Software Developer
                <p className="companyName">Tahyoo</p>
                <ul className="experienceList">
                  <li>
                    Lead Developer/Scrum Master of a social media web
                    application, designed for connecting minorities in the
                    workplace, using React.js and Firebase. The solution is
                    still in production, launching by the end of 2021. Managed
                    backlog, sprint planning, scrum standups, and sprint
                    review/retrospective
                  </li>
                  <li>
                    Managed an agile team of 4 full stack developers and 1 data
                    scientist
                  </li>
                  <li>
                    Worked in tandem with the design team for the creation of
                    wireframes and overall design of the application
                  </li>
                </ul>
              </TimelineItem>
              <TimelineItem title="August 2018 - December 2018">
                Teacher Assistant – Website Development
                <p className="companyName">University of Vermont</p>
                <ul className="experienceList">
                  <li>
                    Worked with Professor Robert Erickson to host and grade
                    weekly labs and assignments on PHP, HTML, and CSS for 50+
                    students
                  </li>
                </ul>
              </TimelineItem>
              <TimelineItem title="Summer 2017">
                Intern
                <p className="companyName">
                  Brazilship/Scanbrasil Comercio Marítimo Ltda.
                </p>
                <ul className="experienceList">
                  <li>
                    Ensured the proper execution of tasks in the IT
                    infrastructure, operation, and commercial departments
                  </li>
                  <li>
                    Performed server virtualization, backup routines, software
                    application, and operational installations
                  </li>
                </ul>
              </TimelineItem>
              <TimelineItem title="Summer 2016">
                Intern
                <p className="companyName">
                  Brazilship/Scanbrasil Comercio Marítimo Ltda.
                </p>
                <ul className="experienceList">
                  <li>
                    Supported the acquisition of client contracts by building
                    reports, providing support for the commercial team during
                    negotiations, and analyzing market data and financial
                    information
                  </li>
                </ul>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
