import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "",
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <i class="icons fas fa-university fa-5x"></i>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Education</h4>
                    <p className="m-0 text-sm">University of Vermont</p>
                    <p className="m-0 text-sm">B.S. in Computer Science</p>
                    <p className="m-0 text-sm">Minor in Mathematics</p>
                    <p className="m-0 text-sm">Graduated May 2019</p>
                    <p className="m-0 text-sm">GPA 3.58/4</p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="100"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <i class="icons fas fa-award fa-5x"></i>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Awards</h4>
                    <p className="m-0 text-sm">Dean's List Fall 2018</p>
                    <p className="m-0 text-sm">Dean's List Spring 2017</p>
                    <p className="m-0 text-sm">Dean's List Fall 2016</p>
                    <p className="m-0 text-sm">3rd Computer Science Fair UVM</p>
                    <p className="m-0 text-sm">4th Agile Codefest UVM</p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="300"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <i class="icons fas fa-id-card-alt fa-5x"></i>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Contact</h4>
                    <p className="m-0 text-sm">
                      Email: <a href="mailto:andreshrj@gmail.com">andreshrj@gmail.com</a>
                    </p>
                    <p className="m-0 text-sm">
                      Phone: <a href="tel:917-399-0491">917.399.0491</a>
                    </p>
                    <p className="m-0 text-sm">
                      Location: New York, NY
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
