import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class IntroTile extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "hero section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container reveal-from-top">
          <div className={innerClasses}>
            <div>
              <h1 className="IntroHeading">
                Hi, my name is <span className="NameColor">Andre Hansen</span>
              </h1>
              <h1 className="IntroHeading IntroHeadingPart2">
                {" "}
                I am a software Developer.
              </h1>
              <ButtonGroup>
                <Button
                  tag="a"
                  color="secondary"
                  href="https://firebasestorage.googleapis.com/v0/b/ashansen-c3536.appspot.com/o/AndreHansenResume.pdf?alt=media&token=2867600f-bfad-42ee-a42a-9610b011be8e"
                  target="_blank"
                  wideMobile
                >
                  Resume
                </Button>
                <Button
                  tag="a"
                  color="dark"
                  href="mailto:andreshrj@gmail.com"
                  wideMobile
                >
                  Contact Me
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

IntroTile.propTypes = propTypes;
IntroTile.defaultProps = defaultProps;

export default IntroTile;
