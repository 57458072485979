import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class News extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "news section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "news-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Projects",
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
            />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require("./../../assets/images/CK.jpg")}
                      alt="News 01"
                      width={344}
                      height={194}
                    />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="https://www.github.com/andresh100/CrazyKeysGame">
                          Crazy Keys
                        </a>
                      </h3>
                      <p className="mb-16 text-sm">
                        This IOS game is made for people to train their hand-eye
                        coordination while typing words as fast as they can. The
                        users are given three different difficulty levels to
                        choose from. Depending on the difficulty level they
                        choose, the keyboard keys will swap positions randomly
                        more/less frequently.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="https://www.github.com/andresh100/CrazyKeysGame">
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require("./../../assets/images/FakeGalaxy.jpg")}
                      alt="News 02"
                      width={344}
                      height={194}
                    />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="https://www.github.com/andresh100/FakeGalaxy">
                          Fake Galaxy
                        </a>
                      </h3>
                      <p className="mb-16 text-sm">
                        A C++ game created for users to pilot and destroy enemy
                        ships in outer space. Multiple enemy waves target the
                        player with a final boss encounter at the end.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="https://www.github.com/andresh100/FakeGalaxy">
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require("./../../assets/images/BYO.jpg")}
                      alt="News 03"
                      width={344}
                      height={194}
                    />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="https://www.github.com/andresh100/BYOArtistPage">
                          BYO Artist Pages
                        </a>
                      </h3>
                      <p className="mb-16 text-sm">
                        This website offers artists a way to create their
                        website based on templates offered. Through this
                        platform, users do not need to worry about coding
                        their website, but only about the content they wish to
                        display.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="https://www.github.com/andresh100/BYOArtistPage">
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;
